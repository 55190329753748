import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "toggler"]

  showForm(event) {
    event.preventDefault()
    this.togglerTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
  }
}
