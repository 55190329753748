import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["comments"]

  scrollToComments(event) {
    event.preventDefault()
    this.commentsTarget.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    })
  }
}