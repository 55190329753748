import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["label", "checkbox"]

  connect() {
    this.updateLabel()
  }

  updateLabel() {
    const checkedLanguages = []
    this.checkboxTargets.filter(el => el.checked).forEach(checkbox => {
      checkedLanguages.push(checkbox.dataset.label)
    })

    let finalLabel = null
    if (checkedLanguages.length > 0) {
      finalLabel = checkedLanguages.join(", ")
    } else {
      finalLabel = this.labelTarget.dataset.labelEmpty
    }

    this.labelTarget.innerHTML = finalLabel
  }
}
